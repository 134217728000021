<template>
  <div class="container-sidebar">
    <div class="content-image">
      <img
        src="@/assets/icons/logo-icon.svg"
        alt=""
      >
    </div>
    <div
      :style="{ height: screenHeight + 'px', overflow: 'auto' }"
      class="text-center"
    >
      <v-tooltip
        right
        transition="fab-transition"
      >
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            to="/home"
            class="buttons-sidebar mt-5"
            active-class="button-selected"
            elevation="0"
            v-bind="attrs"
            v-on="on"
            :style="($route.name == 'Home')? { backgroundColor: '#283C4D', borderRadius: '13px' } :  {}"
          >
            <v-icon
              color="#FFFFFF"
              size="25px"
            >
              mdi-home-variant-outline
            </v-icon>
            <div
              v-if="$route.name == 'Home'"
              class="active-line"
            />
          </v-btn>
        </template>
        <span class="mon-regular">{{ texts.sidebar.textHome }}</span>
      </v-tooltip>
      <v-tooltip
        right
        transition="fab-transition"
      >
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            to="/articles"
            class="buttons-sidebar mt-2"
            active-class="button-selected"
            elevation="0"
            v-bind="attrs"
            v-on="on"
            :style="($route.name == 'Article' || $route.name == 'NewArticle'  || $route.name == 'EditArticle' )? { backgroundColor: '#283C4D !important', borderRadius: '13px' } :  {}"
          >
            <v-icon
              color="#FFFFFF"
              size="23px"
            >
              mdi-view-grid
            </v-icon>
            <div
              v-if="$route.name == 'Article' || $route.name == 'NewArticle'  || $route.name == 'EditArticle' "
              class="active-line"
            />
          </v-btn>
        </template>
        <span class="mon-regular">{{ texts.sidebar.textArticle }}</span>
      </v-tooltip>
      <v-tooltip
        right
        transition="fab-transition"
      >
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            to="/orders"
            class="buttons-sidebar mt-2"
            active-class="button-selected"
            elevation="0"
            v-bind="attrs"
            v-on="on"
            :style="($route.name == 'Orders' || $route.name == 'OrderDetail' )? { backgroundColor: '#283C4D !important', borderRadius: '13px' } :  {}"
          >
            <v-icon
              color="#FFFFFF"
              size="24px"
            >
              mdi-bookmark-outline
            </v-icon>
            <div
              v-if="$route.name == 'Orders' || $route.name == 'OrderDetail'"
              class="active-line"
            />
          </v-btn>
        </template>
        <span class="mon-regular">{{ texts.sidebar.textPurchaseOrder }}</span>
      </v-tooltip>
      <v-tooltip
        right
        transition="fab-transition"
      >
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            to="/disputes"
            class="buttons-sidebar mt-2"
            active-class="button-selected"
            elevation="0"
            v-bind="attrs"
            v-on="on"
            :style="($route.name == 'Disputes' )? { backgroundColor: '#283C4D !important', borderRadius: '13px' } :  {}"
          >
            <img
              width="20px"
              src="@/assets/icons/alert.svg"
              alt=""
            >
            <div
              v-if="$route.name == 'Disputes'"
              class="active-line"
            />
          </v-btn>
        </template>
        <span class="mon-regular">{{ texts.sidebar.textHistorialDisputes }}</span>
      </v-tooltip>
      <v-tooltip
        right
        transition="fab-transition"
      >
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            to="/warehouses"
            class="buttons-sidebar mt-2"
            active-class="button-selected"
            elevation="0"
            v-bind="attrs"
            v-on="on"
            :style="($route.name == 'Warehouses' || $route.name == 'WarehouseDetail')? { backgroundColor: '#283C4D !important', borderRadius: '13px' } :  {}"

          >
            <v-icon
              color="#FFFFFF"
              size="20px"
            >
              mdi-archive-outline
            </v-icon>
            <div
              v-if="$route.name == 'Warehouses' || $route.name == 'WarehouseDetail'"
              class="active-line"
            />
          </v-btn>
        </template>
        <span class="mon-regular">{{ texts.sidebar.textWarehouses }}</span>
      </v-tooltip>
      <v-tooltip
        v-if="$store.state.isManager"
        right
        transition="fab-transition"
      >
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            to="/users"
            class="buttons-sidebar mt-2"
            active-class="button-selected"
            elevation="0"
            v-bind="attrs"
            v-on="on"
          >
            <img
              width="18px"
              src="@/assets/icons/user.svg"
              alt=""
            >
            <div
              v-if="$route.path == '/users'"
              class="active-line"
            />
          </v-btn>
        </template>
        <span class="mon-regular">{{ texts.sidebar.textUsers }}</span>
      </v-tooltip>
      <v-tooltip
        right
        transition="fab-transition"
      >
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            @click="$store.commit('setShowSidebar', false);"
            to="/user-profile"
            class="buttons-sidebar mt-2"
            active-class="button-selected"
            elevation="0"
            v-bind="attrs"
            v-on="on"
          >
            <img
              width="20px"
              src="@/assets/icons/briefcase.svg"
              alt=""
            >
            <div
              v-if="$route.path == '/user-profile'"
              class="active-line"
            />
          </v-btn>
        </template>
        <span class="mon-regular">{{ texts.sidebar.textProfile }}</span>
      </v-tooltip>
      <!--  -->
    </div>
    <div class="content-language text-center">
      <v-btn
        @click="changeLanguage('en')"
        :class="(selectLanguage == 'en') ? 'button-selected buttons-sidebar mt-2': 'buttons-sidebar mt-2'"
        elevation="0"
      >
        <img
          width="20px"
          src="@/assets/icons/eu.svg"
          alt=""
        >
      </v-btn>
      <v-btn
        @click="changeLanguage('sp')"
        :class="(selectLanguage == 'sp') ? 'button-selected buttons-sidebar mt-2 mb-2': 'buttons-sidebar mt-2 mb-2'"
        elevation="0"
      >
        <img
          width="20px"
          src="@/assets/icons/mx.svg"
          alt=""
        >
      </v-btn>
    </div>
    <div class="text-center">
      <v-btn
        @click="closeSession"
        class="buttons-sidebar mt-2 mb-3"
        active-class="button-selected"
        elevation="0"
      >
        <img
          width="20px"
          src="@/assets/icons/close.svg"
          alt=""
        >
      </v-btn>
    </div>
  </div>
</template>

<script>
export default {
  name: "SidebarComponent",
  data() {
    return {
      //VARIABLES
      screenHeight: 0,
      texts: "",
    };
  },
  beforeMount() {
    this.texts = FILE.sidebar[this.selectLanguage];
  },
  created() {
    window.addEventListener("resize", this.handleResize);
    this.handleResize();
  },
  destroyed() {
    window.removeEventListener("resize", this.handleResize);
  },
  methods: {
    changeLanguage: function (e) {
      const config = {
          headers: {
            Authorization: `Bearer ${this.$store.state.sToken}`,
          },
        },
        payload = {};

      DB.patch(
        `${URI}/api/v1/${e}/users/${this.$store.state.sUserId}/languages`,
        payload,
        config
      )
        .then((response) => {
          this.$store.commit("toggle_language", e);
        })
        .catch((error) => {
        });
    },
    closeSession: function () {
      if (this.$store.state.sToken !== "") {
        const config = {
          headers: {
            Authorization: `Bearer ${this.$store.state.sToken}`,
          },
        };

        DB.delete(`${URI}/api/v1/${this.selectLanguage}/session`, config)
          .then((response) => {
            const self = this;

            localStorage.clear();
            this.$store.commit("setIsLogged", false);
            this.$store.commit("setToken", "");
            this.$store.commit("setFullName", "");
            this.$store.commit("setEnterpriseId", "");
            this.$store.commit("setUserId", "");

            setTimeout(function () {
              self.mixSuccess(response.data.message);
            }, 200);
            setTimeout(function () {
              self.$router
                .push({
                  name: "Login",
                })
                .catch(() => {});
            }, 400);
          })
          .catch((error) => {
            this.mixError(error.response.data.message, error.response.status);
          });
      }
    },
    handleResize: function () {
      this.screenHeight = window.innerHeight - 262;
    },
  },
  computed: {
    selectLanguage: function () {
      return this.$store.state.language;
    },
  },
  watch: {
    selectLanguage: function () {
      if (this.selectLanguage) {
        this.texts = FILE.sidebar[this.selectLanguage];
      }
    },
  },
};
</script>

<style scoped>
.active-line {
  width: 8px;
  height: 45px;
  position: absolute;
  left: -25px;
  background: transparent linear-gradient(10deg, #fffc81 0%, #7eff86 100%) 0% 0%
    no-repeat padding-box;
  border-radius: 0px 11px 11px 0px;
}

.buttons-sidebar {
  background-color: transparent !important;
  width: 50px !important;
  height: 50px !important;
}

.button-selected {
  background-color: #283c4d !important;
  border-radius: 13px;
}

.content-language {
  border: 2px solid #283c4d;
}

.content-image {
  width: 100%;
  height: 65px;
  padding: 18px;
  border: 2px solid #283c4d;
}

.content-image img {
  width: 100%;
  height: auto;
}

@supports (object-fit: contain) {
  .content-image img {
    height: 100%;
    object-fit: contain;
    object-position: center center;
  }
}

.container-sidebar {
  background-color: #304458;
}
</style>