var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"container-sidebar"},[_vm._m(0),_c('div',{staticClass:"text-center",style:({ height: _vm.screenHeight + 'px', overflow: 'auto' })},[_c('v-tooltip',{attrs:{"right":"","transition":"fab-transition"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"buttons-sidebar mt-5",style:((_vm.$route.name == 'Home')? { backgroundColor: '#283C4D', borderRadius: '13px' } :  {}),attrs:{"to":"/home","active-class":"button-selected","elevation":"0"}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"color":"#FFFFFF","size":"25px"}},[_vm._v(" mdi-home-variant-outline ")]),(_vm.$route.name == 'Home')?_c('div',{staticClass:"active-line"}):_vm._e()],1)]}}])},[_c('span',{staticClass:"mon-regular"},[_vm._v(_vm._s(_vm.texts.sidebar.textHome))])]),_c('v-tooltip',{attrs:{"right":"","transition":"fab-transition"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"buttons-sidebar mt-2",style:((_vm.$route.name == 'Article' || _vm.$route.name == 'NewArticle'  || _vm.$route.name == 'EditArticle' )? { backgroundColor: '#283C4D !important', borderRadius: '13px' } :  {}),attrs:{"to":"/articles","active-class":"button-selected","elevation":"0"}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"color":"#FFFFFF","size":"23px"}},[_vm._v(" mdi-view-grid ")]),(_vm.$route.name == 'Article' || _vm.$route.name == 'NewArticle'  || _vm.$route.name == 'EditArticle' )?_c('div',{staticClass:"active-line"}):_vm._e()],1)]}}])},[_c('span',{staticClass:"mon-regular"},[_vm._v(_vm._s(_vm.texts.sidebar.textArticle))])]),_c('v-tooltip',{attrs:{"right":"","transition":"fab-transition"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"buttons-sidebar mt-2",style:((_vm.$route.name == 'Orders' || _vm.$route.name == 'OrderDetail' )? { backgroundColor: '#283C4D !important', borderRadius: '13px' } :  {}),attrs:{"to":"/orders","active-class":"button-selected","elevation":"0"}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"color":"#FFFFFF","size":"24px"}},[_vm._v(" mdi-bookmark-outline ")]),(_vm.$route.name == 'Orders' || _vm.$route.name == 'OrderDetail')?_c('div',{staticClass:"active-line"}):_vm._e()],1)]}}])},[_c('span',{staticClass:"mon-regular"},[_vm._v(_vm._s(_vm.texts.sidebar.textPurchaseOrder))])]),_c('v-tooltip',{attrs:{"right":"","transition":"fab-transition"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"buttons-sidebar mt-2",style:((_vm.$route.name == 'Disputes' )? { backgroundColor: '#283C4D !important', borderRadius: '13px' } :  {}),attrs:{"to":"/disputes","active-class":"button-selected","elevation":"0"}},'v-btn',attrs,false),on),[_c('img',{attrs:{"width":"20px","src":require("@/assets/icons/alert.svg"),"alt":""}}),(_vm.$route.name == 'Disputes')?_c('div',{staticClass:"active-line"}):_vm._e()])]}}])},[_c('span',{staticClass:"mon-regular"},[_vm._v(_vm._s(_vm.texts.sidebar.textHistorialDisputes))])]),_c('v-tooltip',{attrs:{"right":"","transition":"fab-transition"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"buttons-sidebar mt-2",style:((_vm.$route.name == 'Warehouses' || _vm.$route.name == 'WarehouseDetail')? { backgroundColor: '#283C4D !important', borderRadius: '13px' } :  {}),attrs:{"to":"/warehouses","active-class":"button-selected","elevation":"0"}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"color":"#FFFFFF","size":"20px"}},[_vm._v(" mdi-archive-outline ")]),(_vm.$route.name == 'Warehouses' || _vm.$route.name == 'WarehouseDetail')?_c('div',{staticClass:"active-line"}):_vm._e()],1)]}}])},[_c('span',{staticClass:"mon-regular"},[_vm._v(_vm._s(_vm.texts.sidebar.textWarehouses))])]),(_vm.$store.state.isManager)?_c('v-tooltip',{attrs:{"right":"","transition":"fab-transition"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"buttons-sidebar mt-2",attrs:{"to":"/users","active-class":"button-selected","elevation":"0"}},'v-btn',attrs,false),on),[_c('img',{attrs:{"width":"18px","src":require("@/assets/icons/user.svg"),"alt":""}}),(_vm.$route.path == '/users')?_c('div',{staticClass:"active-line"}):_vm._e()])]}}],null,false,518556621)},[_c('span',{staticClass:"mon-regular"},[_vm._v(_vm._s(_vm.texts.sidebar.textUsers))])]):_vm._e(),_c('v-tooltip',{attrs:{"right":"","transition":"fab-transition"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"buttons-sidebar mt-2",attrs:{"to":"/user-profile","active-class":"button-selected","elevation":"0"},on:{"click":function($event){return _vm.$store.commit('setShowSidebar', false);}}},'v-btn',attrs,false),on),[_c('img',{attrs:{"width":"20px","src":require("@/assets/icons/briefcase.svg"),"alt":""}}),(_vm.$route.path == '/user-profile')?_c('div',{staticClass:"active-line"}):_vm._e()])]}}])},[_c('span',{staticClass:"mon-regular"},[_vm._v(_vm._s(_vm.texts.sidebar.textProfile))])])],1),_c('div',{staticClass:"content-language text-center"},[_c('v-btn',{class:(_vm.selectLanguage == 'en') ? 'button-selected buttons-sidebar mt-2': 'buttons-sidebar mt-2',attrs:{"elevation":"0"},on:{"click":function($event){return _vm.changeLanguage('en')}}},[_c('img',{attrs:{"width":"20px","src":require("@/assets/icons/eu.svg"),"alt":""}})]),_c('v-btn',{class:(_vm.selectLanguage == 'sp') ? 'button-selected buttons-sidebar mt-2 mb-2': 'buttons-sidebar mt-2 mb-2',attrs:{"elevation":"0"},on:{"click":function($event){return _vm.changeLanguage('sp')}}},[_c('img',{attrs:{"width":"20px","src":require("@/assets/icons/mx.svg"),"alt":""}})])],1),_c('div',{staticClass:"text-center"},[_c('v-btn',{staticClass:"buttons-sidebar mt-2 mb-3",attrs:{"active-class":"button-selected","elevation":"0"},on:{"click":_vm.closeSession}},[_c('img',{attrs:{"width":"20px","src":require("@/assets/icons/close.svg"),"alt":""}})])],1)])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"content-image"},[_c('img',{attrs:{"src":require("@/assets/icons/logo-icon.svg"),"alt":""}})])}]

export { render, staticRenderFns }